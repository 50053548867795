import { useState, Dispatch, SetStateAction, MutableRefObject, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Icon from "../Icon/Icon";
import "./Sidebar.scss";
import abunLogo from "../../assets/images/branding/abun_black_text_logo.png";
import { pageURL } from "../../pages/routes";
import abun_a_logo from "../../assets/images/branding/abun_a_new.png"
import defaultWebIcon from "../../assets/images/icons/content-plan-web-default.png";
import { useMutation } from "@tanstack/react-query";
import {
	switchWebsiteMutation,
} from "../../utils/api";
import GenericButton from "../GenericButton/GenericButton";
import { featureRequestMutation } from "../../utils/api";
import AbunModal from "../AbunModal/AbunModal";
import TextArea from "../TextArea/TextArea";

interface SidebarProps {
	activeWebsiteDomain: string | null
	activeWebsiteLogo: string | null
	website_list: Array<UserWebsite>
	basePath: string | null
	showCollapsedSidebar: boolean
	currentPlanName: string
	setShowCollapsedSidebar: Dispatch<SetStateAction<boolean>>
	successAlertRef: MutableRefObject<any>
	failAlertRef: MutableRefObject<any>
}

interface featureRequestResponse {
	success: boolean,
	message: string
}

export interface UserWebsite {
	domain: string
	logo_url: string
}


export default function Sidebar(props: SidebarProps) {
	// --------------------------- STATES ---------------------------
	const [websiteDropdownActive, setWebsiteDropdownActive] = useState<boolean>(false);
	const [hamburgerActive, setHamburgerActive] = useState<boolean>(false);
	const { showCollapsedSidebar, setShowCollapsedSidebar } = props;
	const [featureRequestMessage, setFeatureRequestMessage] = useState("");
	const [featureRequestErrorMessage, setFeatureRequestErrorMessage] = useState("");
	const [featureRequestSuccessMessage, setFeatureRequestSuccessMessage] = useState("");
	const [featureRequestModalActive, setFeatureRequestModalActive] = useState(false);

	// --------------------------- MUTATIONS ---------------------------
	const switchActiveWebsite = useMutation(switchWebsiteMutation);
	const featureRequestMut = useMutation(featureRequestMutation);


	// =================================================================
	// --------------------------- MAIN CODE ---------------------------
	// =================================================================

	function handleWebsiteChange(newWebsiteDomain: string) {
		switchActiveWebsite.mutate(newWebsiteDomain, {
			onSuccess: () => {
				window.location.reload();
			},
			onError: (error) => {
				console.error(error);
			}
		});
	}

	function checkForUnsavedChanges(event: any) {
		let unsavedSettings: boolean = JSON.parse(localStorage.getItem("unsavedSettings") || "false");
		if (unsavedSettings) {
			if (window.confirm("You have unsaved changes. Are you sure you want to leave this page?")) {
				localStorage.setItem("unsavedSettings", "false");
			} else {
				// prevent navigation
				event.preventDefault();
				return false;
			}
		} else {
			localStorage.removeItem("unsavedSettings");
		}
	}

	function featureRequestModalContent() {
		return (
			<>
				<div className={"integration-modal-logo"}>
					<img src={abunLogo} alt="abun_logo" width={"auto"} height={64} />
				</div>
				<div className={"block"}>
					<p>
						We're always looking to improve and your feedback is invaluable to us.
					</p>
				</div>
				<div className={"block"}>
					<p>
						If you have a suggestion for a new feature or enhancement, please let us know! Describe the feature you would like to see in below message box.
					</p>
				</div>
				<div className={"block"}>
					<p className={"font-secondary"}>
						We appreciate your input and look forward to hearing your ideas!
					</p>
				</div>
				<div className={"mt-5"}>
					<label className={"label"}>
						Describe the feature you want
						<TextArea value={featureRequestMessage}
							placeholder={"Please Describe Your Idea."}
							onChange={(val) => {
								setFeatureRequestMessage(val);
							}} />
					</label>
				</div>
				<GenericButton text={featureRequestMut.isLoading ? "Sending..." : "Send"}
					type={"success"}
					disable={featureRequestMut.isLoading}
					additionalClassList={["mt-6", "pl-6", "pr-6"]}
					clickHandler={() => {
						setFeatureRequestErrorMessage("");
						setFeatureRequestSuccessMessage("");

						const featureRequestMessageLength = featureRequestMessage.length;
						if (featureRequestMessageLength < 20 || featureRequestMessageLength > 500) {
							setFeatureRequestErrorMessage("Please enter a message between 20-500 characters.");
						} else {
							featureRequestMut.mutate(featureRequestMessage, {
								onSuccess: (data) => {
									let response: featureRequestResponse = data['data'];
									if (response.success) {
										setFeatureRequestSuccessMessage(response.message);
									} else {
										setFeatureRequestErrorMessage(response.message);
									}
								},
								onError: () => {
									setFeatureRequestErrorMessage("Oops! Something went wrong. Please try again in some time.");
								}
							});
						}
					}} />
				<div className="mt-4">
					<p className={"has-text-danger"}>{featureRequestErrorMessage}</p>
					<p className={"has-text-success"}>{featureRequestSuccessMessage}</p>
				</div>
			</>
		)
	}

	

	return (
		<aside className={`menu sidebar ${hamburgerActive ? "full-height" : ""} ${showCollapsedSidebar ? "collapsed" : ""}`}>
			{/* ============================================================================================= */}
			{/* ----------------------------------- Feature Request Modal ----------------------------------- */}
			{/* ============================================================================================= */}
			<AbunModal active={featureRequestModalActive}
				headerText={"Abun Feature Request"}
				closeable={true}
				hideModal={() => {
					setFeatureRequestModalActive(false);
					setFeatureRequestErrorMessage("");
					setFeatureRequestSuccessMessage("");
				}}>
				{featureRequestModalContent()}
			</AbunModal>

			<div className={"mobile-extra"}>
				<img src={abunLogo} alt="Abun Logo" className={"mobile-logo"} />
				<span className={"hamburger"} onClick={() => {
					setHamburgerActive(!hamburgerActive);
				}}>
					<Icon iconName={"hamburger"} height={"1.65em"} width={"1.65em"} />
				</span>
			</div>
			<section>
				<div className="sidebar-header">
					{/* ------------------------- LOGO ------------------------- */}
					<div className="logo-container">
						<img src={abunLogo} className={`abun-logo ${showCollapsedSidebar ? "is-hidden" : ""}`} alt="Abun Logo" width="128" height="auto" />
						<img src={abun_a_logo} className={`abun-logo ${!showCollapsedSidebar ? "is-hidden" : ""}`} alt="Abun Logo" width="64" height="auto" />
						<div className="triangle-btn is-clickable" onClick={() => setShowCollapsedSidebar((prev) => !prev)} />
					</div>

					{/* ------------------------- WEBSITE SELECTION DROPDOWN ------------------------- */}
					{/* <ul className={"menu-list mt-4"}>
						<div className={`dropdown w-100 ${websiteDropdownActive && "is-active"}`}
							onClick={() => {
								setWebsiteDropdownActive(!websiteDropdownActive)
								// on clicking outside the dropdown, close the dropdown
								document.addEventListener("click", (e) => {
									if (!(e.target as HTMLElement).closest(".dropdown")) {
										setWebsiteDropdownActive(false);
									}
								});
							}
							}>
							<div className="dropdown-trigger w-100">
								<button className="button w-100 is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
									{props.activeWebsiteDomain ?
										<span>
											<img src={props.activeWebsiteLogo as string}
												alt={"website logo"}
												onClick={checkForUnsavedChanges} className={"sidebar-website-list-logo"}
												width={20}
												height={"auto"}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null
													currentTarget.src = defaultWebIcon;
												}} />
											<span className="link-text ml-2">{props.activeWebsiteDomain}</span>
										</span> :
										<span><Icon iconName={"plus-circle"} additionalClasses={["icon-primary"]} /><span className="link-text ml-2">Select Website</span></span>}
									<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 1L6 6L11 1" stroke="black" strokeLinecap="round" />
									</svg>
								</button>
							</div>
							<div className="dropdown-menu" id="dropdown-menu" role="menu">
								<div className="dropdown-content">
									{props.website_list.map(website => (
										<div key={website.domain}>
											<div className="dropdown-item sidebar-user-website-list-item"
												onClick={() => {
													if (props.activeWebsiteDomain !== website.domain) {
														handleWebsiteChange(website.domain);
													}
												}}>
												<img src={website.logo_url}
													alt={"website logo"}
													onClick={checkForUnsavedChanges} className={"sidebar-website-list-logo"}
													width={20}
													height={"auto"}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null
														currentTarget.src = defaultWebIcon;
													}} />
												<span className={`ml-2 ${props.activeWebsiteDomain === website.domain ? "has-text-primary" : ""}`}>
													{website.domain}
												</span>
											</div>
											<hr className="dropdown-divider" />
										</div>
									))}
									<NavLink className={"dropdown-item sidebar-connect-website-list-item"} to={pageURL['connectWebsite']}>
										<Icon iconName={"plus-circle"} />
										<span className="ml-2">Add Website</span>
									</NavLink>
								</div>
							</div>
						</div>
					</ul> */}
				</div>

				{/* ------------------------- SIDEBAR ITEMS ------------------------- */}
				<div className="sidebar-items">
					<ul className="menu-list mt-4">
						{/* ------------------------- CONTENT PLAN ------------------------- */}
						{/* <li className="mt-2 category-menu content-plan">
							<NavLink to={pageURL['contentPlan']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" strokeWidth="3" height="48" width="48">
									<path id="hover" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M11.0026 8.56201C9.86637 8.67177 8.88387 8.78726 8.05131 8.89823C5.85219 9.19137 4.1737 10.8462 3.85898 13.0423C3.45142 15.8862 3 20.4106 3 26.4999C3 32.5892 3.45142 37.1136 3.85898 39.9575C4.1737 42.1536 5.85219 43.8084 8.0513 44.1015C11.1817 44.5188 16.4319 44.9999 24 44.9999C31.5681 44.9999 36.8183 44.5188 39.9487 44.1015C42.1478 43.8084 43.8263 42.1536 44.141 39.9575C44.5486 37.1136 45 32.5892 45 26.4999C45 20.4106 44.5486 15.8862 44.141 13.0423C43.8263 10.8462 42.1478 9.19137 39.9487 8.89823C39.1161 8.78726 38.1336 8.67177 36.9974 8.56201" />
									<path stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M29.0001 8.07403C27.4622 8.02711 25.7964 8 24 8C22.2036 8 20.5379 8.02711 19 8.07403" />
									<path stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M29.0131 9.26993C29.0564 11.3499 30.4217 12.9643 32.5019 12.9963C32.6608 12.9987 32.8267 13 33 13C33.1733 13 33.3392 12.9987 33.4981 12.9963C35.5783 12.9643 36.9436 11.3499 36.9869 9.26993C36.9951 8.87467 37 8.45026 37 8C37 7.54974 36.9951 7.12533 36.9869 6.73007C36.9436 4.65009 35.5783 3.03574 33.4981 3.00374C33.3392 3.0013 33.1733 3 33 3C32.8267 3 32.6608 3.0013 32.5019 3.00374C30.4217 3.03574 29.0564 4.65009 29.0131 6.73007C29.0049 7.12533 29 7.54974 29 8C29 8.45026 29.0049 8.87467 29.0131 9.26993Z" />
									<path stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M11.0131 9.26993C11.0564 11.3499 12.4217 12.9643 14.5019 12.9963C14.6608 12.9987 14.8267 13 15 13C15.1733 13 15.3392 12.9987 15.4981 12.9963C17.5783 12.9643 18.9436 11.3499 18.9869 9.26993C18.9951 8.87467 19 8.45026 19 8C19 7.54974 18.9951 7.12533 18.9869 6.73007C18.9436 4.65009 17.5783 3.03574 15.4981 3.00374C15.3392 3.0013 15.1733 3 15 3C14.8267 3 14.6608 3.0013 14.5019 3.00374C12.4217 3.03574 11.0564 4.65009 11.0131 6.73007C11.0049 7.12533 11 7.54974 11 8C11 8.45026 11.0049 8.87467 11.0131 9.26993Z" />
									<path stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M21 25L33 25" />
									<path stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M16 25H15" />
									<path stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M21 34H29" />
									<path stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M16 34H15" />
								</svg>
								<span className="link-text ml-4">Content Plan</span>
							</NavLink>
						</li> */}

						{/* ------------------------- ARTICLES ------------------------- */}
						{/* 
						<li className="mt-2 articles subcategory-menu">
							<NavLink to={pageURL['articles']}
								className={({ isActive }) => isActive ? "is-active" :
									`${document.getElementById("howToArticles")?.classList.contains("is-active") ? "is-active" : `${document.getElementById("listicles")?.classList.contains("is-active") ? "is-active" : ""}`}`}
								onClick={checkForUnsavedChanges}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Vector" fill="#3f77f8" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract" fill="#3f77f8" d="M37.9998 3c-4.4569 0.99986 -4.5702 6.93788 -4.4576 9.9987 4.3993 -0.0219 7.398 -0.3069 9.2207 -0.5631 1.3335 -0.1874 2.2369 -1.3427 2.2369 -2.68934v-0.6487c0 -3.90244 -2.9999 -6.09756 -6 -6.09756l-1 0Z" strokeWidth="3" />
										<path id="Vector_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m37.9996 3.00001 1 -0.00001c3.0001 0 6 2.19512 6 6.09756v0.6487c0 1.34664 -0.9034 2.50194 -2.2369 2.68934 -1.8227 0.2562 -4.8214 0.5412 -9.2207 0.5631" strokeWidth="3" />
										<path id="Vector 1679" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M17 13h10" strokeWidth="3" /><path id="Vector 1680" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13 20 14 0" strokeWidth="3" />
										<path id="Vector 1681" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13.5 27 14 0" strokeWidth="3" /><path id="Vector 1682" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m14 34 14 0" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Articles</span>
							</NavLink>
							<ul className="ml-4">
								<li className="is-size-7">
									<NavLink to={pageURL['articles']} id="generalArticles"
										className={({ isActive }) => isActive ? (window.location.pathname.includes("/articles/edit/listicle-" || "/articles/edit/how-to-article-") ? "" : "is-active") : ""}
										onClick={checkForUnsavedChanges}>
										<span className="link-text ml-3">
											General Articles
										</span>
									</NavLink>
								</li>
								<li className="is-size-7">
									{props.currentPlanName === "Trial" ?
										<NavLink to={pageURL['howToArticles']} className={"upgrade"} onClick={checkForUnsavedChanges}>
											<span className="link-text ml-3">
												How-to Articles
											</span>
											<span className="tag is-info is-light is-rounded">
												Upgrade ⚡
											</span>
										</NavLink>
										:
										<NavLink to={pageURL['howToArticles']} id="howToArticles"
											className={({ isActive }) => isActive ? "is-active" : (window.location.pathname.includes("/articles/edit/how-to-article-") ? "is-active" : "")}
											onClick={checkForUnsavedChanges}>
											<span className="link-text ml-3">
												How-to Articles
											</span>
										</NavLink>
									}
								</li>
								<li className="is-size-7">
									{props.currentPlanName === "Trial" ?
										<NavLink to={pageURL['listicles']} className={"upgrade"} onClick={checkForUnsavedChanges}>
											<span className="link-text ml-3">
												List-Based Articles
											</span>
											<span className="tag is-info is-light is-rounded">
												Upgrade ⚡
											</span>
										</NavLink>
										:
										<NavLink to={pageURL['listicles']} id="listicles"
											onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : (window.location.pathname.includes("/articles/edit/listicle-") ? "is-active" : "")}>
											<span className="link-text ml-3">
												List-Based Articles
											</span>
										</NavLink>
									}
								</li>
								<li className="is-size-7">
									{props.currentPlanName === "Trial" ?
										<NavLink to={pageURL['customArticles']} className={"upgrade"} onClick={checkForUnsavedChanges}>
											<span className="link-text ml-3">
												Custom Articles
											</span>
											<span className="tag is-info is-light is-rounded">
												Upgrade ⚡
											</span>
										</NavLink>
										:
										<NavLink to={pageURL['customArticles']} id="listicles"
											onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : (window.location.pathname.includes("/articles/edit/listicle-") ? "is-active" : "")}>
											<span className="link-text ml-3">
												Custom Articles
											</span>
										</NavLink>
									}
								</li>
								<li className="is-size-7">
									{props.currentPlanName === "Trial" ?
										<NavLink to={pageURL['customArticles']} className={"upgrade"} onClick={checkForUnsavedChanges}>
											<span className="link-text ml-3">
												Bulk Custom Articles
											</span>
											<span className="tag is-info is-light is-rounded">
												Upgrade ⚡
											</span>
										</NavLink>
										:
										<NavLink to={pageURL['customArticles']} id="listicles"
											onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : (window.location.pathname.includes("/articles/edit/listicle-") ? "is-active" : "")}>
											<span className="link-text ml-3">
												Bulk Custom Articles
											</span>
										</NavLink>
									}
								</li>
								{/* <li className="is-size-7">
									<NavLink to={pageURL['articles']} className="coming-soon">
										<span>
											Podcast to Articles
										</span>
										<span className="tag is-info is-light is-rounded">
											Coming soon
										</span>
									</NavLink>
								</li>
								<li className="is-size-7">
									<NavLink to={pageURL['articles']} className="coming-soon">
										<span>
											Alternative to Articles
										</span>
										<span className="tag is-info is-light is-rounded">
											Coming soon
										</span>
									</NavLink>
								</li>
								<li className="is-size-7">
									<NavLink to={pageURL['articles']} className="coming-soon">
										<span>
											Programmatic SEO Articles
										</span>
										<span className="tag is-info is-light is-rounded">
											Coming soon
										</span>
									</NavLink>
								</li> 
							</ul>
						</li>
						*/}


						{/* ------------------------- KEYWORDS ------------------------- */}
						{/* 
						<li className={"keywords subcategory-menu"}>
							<NavLink to={pageURL['selectedKeywords']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : `${document.getElementById("listicleKeywords")?.classList.contains("is-active") ? "is-active" : `${document.getElementById("competitorResearch")?.classList.contains("is-active") ? "is-active" : ""}`}`}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Ellipse 19" fill="#fff" d="M21 36a9 9 0 1 1 -18 0 9 9 0 0 1 18 0Z" strokeWidth="3" />
										<path id="Rectangle 125" fill="#fff" d="M20.865 6.597c-0.104 -1.723 -1.225 -3.058 -2.937 -3.28C16.606 3.143 14.696 3 12 3c-2.696 0 -4.606 0.144 -5.928 0.316 -1.711 0.224 -2.833 1.558 -2.937 3.281C3.06 7.83 3 9.573 3 12c0 2.427 0.06 4.17 0.135 5.403 0.104 1.723 1.226 3.058 2.937 3.28C7.394 20.857 9.304 21 12 21c2.696 0 4.606 -0.144 5.928 -0.316 1.712 -0.224 2.833 -1.558 2.937 -3.281C20.94 16.17 21 14.427 21 12c0 -2.427 -0.06 -4.17 -0.135 -5.403Z" strokeWidth="3" />
										<path id="Vector" fill="#fff" d="M36.066 20.5c2.797 0 5.233 -0.245 7.008 -0.499 1.684 -0.24 2.647 -1.908 2.013 -3.486a49.992 49.992 0 0 0 -3.071 -6.318 49.928 49.928 0 0 0 -3.938 -5.82c-1.05 -1.337 -2.975 -1.337 -4.025 0a49.979 49.979 0 0 0 -3.936 5.82 49.95 49.95 0 0 0 -3.072 6.318c-0.632 1.578 0.33 3.246 2.013 3.486a49.99 49.99 0 0 0 7.008 0.499Z" strokeWidth="3" />
										<path id="Ellipse 20" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M21 36a9 9 0 1 1 -18 0 9 9 0 0 1 18 0Z" strokeWidth="3" />
										<path id="Rectangle 126" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.865 6.597c-0.104 -1.723 -1.225 -3.058 -2.937 -3.28C16.606 3.143 14.696 3 12 3c-2.696 0 -4.606 0.144 -5.928 0.316 -1.711 0.224 -2.833 1.558 -2.937 3.281C3.06 7.83 3 9.573 3 12c0 2.427 0.06 4.17 0.135 5.403 0.104 1.723 1.226 3.058 2.937 3.28C7.394 20.857 9.304 21 12 21c2.696 0 4.606 -0.144 5.928 -0.316 1.712 -0.224 2.833 -1.558 2.937 -3.281C20.94 16.17 21 14.427 21 12c0 -2.427 -0.06 -4.17 -0.135 -5.403Z" strokeWidth="3" />
										<path id="Vector_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M35.986 20.5c2.796 0 5.233 -0.245 7.008 -0.499 1.683 -0.24 2.646 -1.908 2.013 -3.486a49.98 49.98 0 0 0 -3.072 -6.318 49.916 49.916 0 0 0 -3.937 -5.82c-1.05 -1.337 -2.975 -1.337 -4.025 0a49.99 49.99 0 0 0 -3.936 5.82 49.947 49.947 0 0 0 -3.072 6.318c-0.633 1.578 0.33 3.246 2.013 3.486 1.775 0.254 4.211 0.499 7.008 0.499Z" strokeWidth="3" />
										<path id="Vector 2536" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M28 36h17" strokeWidth="3" />
										<path id="Vector 2537" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M28 29h17" strokeWidth="3" />
										<path id="Vector 2538" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M28 43h17" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Keywords</span>
							</NavLink>
							<ul className="ml-4">
								<li className=" is-size-7">
									<NavLink to={pageURL['selectedKeywords']} id="selectedKeywords"
										onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
										<span className="link-text ml-3">
											Selected Keywords
										</span>
									</NavLink>
								</li>
								<li className="is-size-7">
									{props.currentPlanName === "Trial" ?
										<NavLink to={pageURL['manageSubscription']} className={"upgrade"} onClick={checkForUnsavedChanges}>
											<span className="link-text ml-3">
												List-Based Article Keywords
											</span>
											<span className="tag is-info is-light is-rounded">
												Upgrade ⚡
											</span>
										</NavLink>
										:
										<NavLink to={pageURL['listicleKeywords']} id="listicleKeywords"
											onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
											<span className="link-text ml-3">
												List-Based Article Keywords
											</span>
										</NavLink>
									}
								</li>
								{/* <li className="mt-2 is-size-7">
									<NavLink to={pageURL['aiResearchKeywords']}
											onClick={checkForUnsavedChanges} className={({isActive}) => isActive ? "is-active" : ""}>
										<span>
											AI Keyword Research
										</span>
									</NavLink>
								</li>
								<li className="is-size-7">
									<NavLink to={pageURL['competitorResearch']} id="competitorResearch"
										onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
										<span className="link-text ml-3">
											Steal Competitor Keywords
										</span>
									</NavLink>
								</li>
							</ul>
						</li> */}

						{/* ------------------------- ARTICLES ------------------------- */}
						{/* <li className="mt-2 articles subcategory-menu">
							<NavLink to={pageURL['CreateArticlesV2']} 
									className={({ isActive }) => isActive ? "is-active" : ""} onClick={checkForUnsavedChanges}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Vector" fill="#3f77f8" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract" fill="#3f77f8" d="M37.9998 3c-4.4569 0.99986 -4.5702 6.93788 -4.4576 9.9987 4.3993 -0.0219 7.398 -0.3069 9.2207 -0.5631 1.3335 -0.1874 2.2369 -1.3427 2.2369 -2.68934v-0.6487c0 -3.90244 -2.9999 -6.09756 -6 -6.09756l-1 0Z" strokeWidth="3" />
										<path id="Vector_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m37.9996 3.00001 1 -0.00001c3.0001 0 6 2.19512 6 6.09756v0.6487c0 1.34664 -0.9034 2.50194 -2.2369 2.68934 -1.8227 0.2562 -4.8214 0.5412 -9.2207 0.5631" strokeWidth="3" />
										<path id="Vector 1679" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M17 13h10" strokeWidth="3" /><path id="Vector 1680" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13 20 14 0" strokeWidth="3" />
										<path id="Vector 1681" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13.5 27 14 0" strokeWidth="3" /><path id="Vector 1682" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m14 34 14 0" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Article Titles</span>
							</NavLink>
						</li> */}

						{/* ------------------------- DASHBOARD ------------------------- */}
						{/* <li className="category-menu dashboard">
							<NavLink to={pageURL['dashboard']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="hover" fill="#fff" d="M26.8148 4.15979c7.2347 3.21132 12.7189 7.42931 15.5541 9.86701 1.4328 1.232 2.2317 3.0051 2.3306 4.8921C44.8354 21.5103 45 25.5652 45 30c0 3.4116 -0.0974 6.7062 -0.2042 9.293 -0.1239 3.0011 -2.5102 5.3682 -5.5121 5.4707 -3.4603 0.1181 -8.5948 0.2363 -15.2836 0.2363 -6.6889 0 -11.8234 -0.1182 -15.28377 -0.2363 -3.00187 -0.1025 -5.38809 -2.4695 -5.51204 -5.4706C3.09745 36.7063 3 33.4116 3 30c0 -4.4348 0.16468 -8.4898 0.30058 -11.0812 0.09897 -1.887 0.89784 -3.66 2.33063 -4.8919 2.83511 -2.4377 8.31939 -6.65576 15.55409 -9.86711 1.7913 -0.79509 3.8383 -0.79509 5.6295 0Z" strokeWidth="3" />
										<path stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M26.8148 4.15979c7.2347 3.21132 12.7189 7.42931 15.5541 9.86701 1.4328 1.232 2.2317 3.0051 2.3306 4.8921C44.8354 21.5103 45 25.5652 45 30c0 3.4116 -0.0974 6.7062 -0.2042 9.293 -0.1239 3.0011 -2.5102 5.3682 -5.5121 5.4707 -3.4603 0.1181 -8.5948 0.2363 -15.2836 0.2363 -6.6889 0 -11.8234 -0.1182 -15.28377 -0.2363 -3.00187 -0.1025 -5.38809 -2.4695 -5.51204 -5.4706C3.09745 36.7063 3 33.4116 3 30c0 -4.4348 0.16468 -8.4898 0.30058 -11.0812 0.09897 -1.887 0.89784 -3.66 2.33063 -4.8919 2.83511 -2.4377 8.31939 -6.65576 15.55409 -9.86711 1.7913 -0.79509 3.8383 -0.79509 5.6295 0Z" strokeWidth="3" />
										<path stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m16 38 16 0" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Dashboard</span>
							</NavLink>
						</li> */}

						{/* ------------------------- AI Keywords ------------------------- */}
						<li className="mt-2 articles subcategory-menu">
							<NavLink to={pageURL['keywordResearch']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
									<g>
										<path id="hover" fill="#ffffff" d="M15.893862500000001 13.730472916666669C16.450797916666666 12.797679166666668 16.770833333333336 11.707047916666667 16.770833333333336 10.541666666666668c0 -3.440272916666667 -2.7888937499999997 -6.229166666666667 -6.229166666666667 -6.229166666666667S4.3125 7.10139375 4.3125 10.541666666666668s2.7888937499999997 6.229166666666667 6.229166666666667 6.229166666666667c1.1819125000000001 0 2.2869187500000003 -0.3291395833333333 3.228385416666667 -0.9007854166666667 0.00876875 0.010541666666666666 0.0175375 0.02113125 0.026258333333333335 0.03167291666666667 0.5273708333333333 0.6362375000000001 1.0464041666666668 1.2624125 1.6276333333333333 1.8799145833333333 0.5202791666666667 0.55271875 1.3054895833333333 0.6233 1.8571062500000002 0.10182291666666667 0.09856458333333333 -0.09319791666666667 0.20565833333333336 -0.19717708333333334 0.32166458333333336 -0.3131833333333333 0.11600625 -0.11600625 0.21998541666666668 -0.22310000000000002 0.3131833333333333 -0.32166458333333336 0.5214770833333334 -0.5516166666666666 0.4508479166666667 -1.3368270833333333 -0.10187083333333334 -1.8571062500000002 -0.6174541666666666 -0.5812291666666667 -1.2436291666666668 -1.1002625 -1.8798666666666668 -1.6275854166666668 -0.013464583333333334 -0.011164583333333334 -0.02688125 -0.02228125 -0.04029791666666666 -0.033445833333333334Z" strokeWidth="1" />
										<path id="Ellipse 18" fill="#ffffff" d="M7.666666666666667 10.541666666666668a2.875 2.875 0 1 0 5.75 0 2.875 2.875 0 1 0 -5.75 0" strokeWidth="1" /><path id="Vector" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M6.229166666666667 1.4375s-1.4786604166666668 0.00001916666666666667 -3.045636041666667 0.092646875c-0.8948485416666667 0.05289520833333334 -1.6002729166666667 0.758209375 -1.6532495833333334 1.6530579166666668C1.4375 4.75039125 1.4375 6.229166666666667 1.4375 6.229166666666667M16.770833333333336 1.4375s1.4786604166666668 0.00001916666666666667 3.04563125 0.092646875c0.89484375 0.05289520833333334 1.6002729166666667 0.758209375 1.65326875 1.6530579166666668C21.5625 4.75039125 21.5625 6.229166666666667 21.5625 6.229166666666667M16.770833333333336 21.5625s1.4786604166666668 0 3.04563125 -0.09262291666666667c0.89484375 -0.0529 1.6002729166666667 -0.7582333333333334 1.65326875 -1.6530770833333335C21.5625 18.249589583333336 21.5625 16.770833333333336 21.5625 16.770833333333336M6.229166666666667 21.5625s-1.4786604166666668 0 -3.045636041666667 -0.09262291666666667c-0.8948485416666667 -0.0529 -1.6002729166666667 -0.7582333333333334 -1.6532495833333334 -1.6530770833333335C1.4375 18.249589583333336 1.4375 16.770833333333336 1.4375 16.770833333333336" strokeWidth="1" />
										<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M15.89371875 13.730472916666669c0.5569833333333334 -0.9327937500000001 0.8770187500000001 -2.023425 0.8770187500000001 -3.18880625 0 -3.440272916666667 -2.7888937499999997 -6.229166666666667 -6.229166666666667 -6.229166666666667S4.312385 7.10139375 4.312385 10.541666666666668 7.101297916666667 16.770833333333336 10.541570833333333 16.770833333333336c1.1818645833333334 0 2.2869187500000003 -0.3291395833333333 3.228385416666667 -0.9007854166666667 0.008720833333333334 0.010541666666666666 0.017489583333333333 0.02113125 0.026258333333333335 0.03167291666666667 0.5273708333333333 0.6362375000000001 1.0464041666666668 1.2624125 1.6275854166666668 1.8799145833333333 0.5202791666666667 0.55271875 1.3054895833333333 0.6233 1.8571062500000002 0.10182291666666667 0.0986125 -0.09319791666666667 0.20565833333333336 -0.19717708333333334 0.32166458333333336 -0.3131833333333333 0.11600625 -0.11600625 0.22003333333333333 -0.22310000000000002 0.31323124999999996 -0.32166458333333336 0.5214291666666667 -0.5516166666666666 0.4508479166666667 -1.3368270833333333 -0.10187083333333334 -1.8571062500000002 -0.6174541666666666 -0.5812291666666667 -1.2436770833333333 -1.1002625 -1.8799145833333333 -1.6275854166666668 -0.013416666666666667 -0.011164583333333334 -0.026833333333333334 -0.02228125 -0.04029791666666666 -0.033445833333333334Z" strokeWidth="1" />
										<path id="hover" fill="#ffffff" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M7.666666666666667 10.541666666666668a2.875 2.875 0 1 0 5.75 0 2.875 2.875 0 1 0 -5.75 0" strokeWidth="1" />
									</g>
								</svg>
								<span className="link-text ml-4">KW Research & Create Articles</span>
							</NavLink>
						</li>

						{/* ------------------------- Show Articles ------------------------- */}
						<li className="mt-2 articles subcategory-menu">
							<NavLink to={pageURL['showArticles']}
								className={({ isActive }) => isActive ? "is-active" :
									`${document.getElementById("howToArticles")?.classList.contains("is-active") ? "is-active" : `${document.getElementById("listicles")?.classList.contains("is-active") ? "is-active" : ""}`}`}
								onClick={checkForUnsavedChanges}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Vector" stroke="#2859c5" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract" stroke="#2859c5" d="M37.9998 3c-4.4569 0.99986 -4.5702 6.93788 -4.4576 9.9987 4.3993 -0.0219 7.398 -0.3069 9.2207 -0.5631 1.3335 -0.1874 2.2369 -1.3427 2.2369 -2.68934v-0.6487c0 -3.90244 -2.9999 -6.09756 -6 -6.09756l-1 0Z" strokeWidth="3" />
										<path id="Vector_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m37.9996 3.00001 1 -0.00001c3.0001 0 6 2.19512 6 6.09756v0.6487c0 1.34664 -0.9034 2.50194 -2.2369 2.68934 -1.8227 0.2562 -4.8214 0.5412 -9.2207 0.5631" strokeWidth="3" />
										<path id="Vector 1679" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M17 13h10" strokeWidth="3" /><path id="Vector 1680" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13 20 14 0" strokeWidth="3" />
										<path id="Vector 1681" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m13.5 27 14 0" strokeWidth="3" /><path id="Vector 1682" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m14 34 14 0" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Generated Articles</span>
							</NavLink>
						</li>

						{/* ------------------------- AI Keyword Results ------------------------- */}
						{/* <li className="mt-2 articles subcategory-menu">
							<NavLink to={pageURL['showKeywords']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Vector" fill="#3f77f8" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract" fill="#3f77f8" d="M37.9998 3c-4.4569 0.99986 -4.5702 6.93788 -4.4576 9.9987 4.3993 -0.0219 7.398 -0.3069 9.2207 -0.5631 1.3335 -0.1874 2.2369 -1.3427 2.2369 -2.68934v-0.6487c0 -3.90244 -2.9999 -6.09756 -6 -6.09756l-1 0Z" strokeWidth="3" />
										<path id="Vector_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m37.9996 3.00001 1 -0.00001c3.0001 0 6 2.19512 6 6.09756v0.6487c0 1.34664 -0.9034 2.50194 -2.2369 2.68934 -1.8227 0.2562 -4.8214 0.5412 -9.2207 0.5631" strokeWidth="3" />
										<path id="Vector 1679" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M17 13h10" strokeWidth="3" /><path id="Vector 1680" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13 20 14 0" strokeWidth="3" />
										<path id="Vector 1681" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13.5 27 14 0" strokeWidth="3" /><path id="Vector 1682" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m14 34 14 0" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">AI Keyword Results</span>
							</NavLink>
						</li> */}

						{/* ------------------------- CONTENT CALENDAR ------------------------- */}
						{/* <li className="mt-2 articles subcategory-menu">
							<NavLink to={pageURL['contentCalendar']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Vector" fill="#3f77f8" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract" fill="#3f77f8" d="M37.9998 3c-4.4569 0.99986 -4.5702 6.93788 -4.4576 9.9987 4.3993 -0.0219 7.398 -0.3069 9.2207 -0.5631 1.3335 -0.1874 2.2369 -1.3427 2.2369 -2.68934v-0.6487c0 -3.90244 -2.9999 -6.09756 -6 -6.09756l-1 0Z" strokeWidth="3" />
										<path id="Vector_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
										<path id="Subtract_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m37.9996 3.00001 1 -0.00001c3.0001 0 6 2.19512 6 6.09756v0.6487c0 1.34664 -0.9034 2.50194 -2.2369 2.68934 -1.8227 0.2562 -4.8214 0.5412 -9.2207 0.5631" strokeWidth="3" />
										<path id="Vector 1679" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M17 13h10" strokeWidth="3" /><path id="Vector 1680" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13 20 14 0" strokeWidth="3" />
										<path id="Vector 1681" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13.5 27 14 0" strokeWidth="3" /><path id="Vector 1682" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m14 34 14 0" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Content Calendar</span>
							</NavLink>
						</li> */}

						{/* ------------------------- Content Automation ------------------------- */}
						<li className="mt-2 articles subcategory-menu">
							{props.currentPlanName === "Trial" ?
								<NavLink to={pageURL['manageSubscription']} className={"upgrade"} onClick={checkForUnsavedChanges}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
										<g>
											<path id="Subtract" fill="#ffffff" d="M2.4035047916666668 14.614104166666667C2.3985310416666668 14.233070833333334 2.3958333333333335 13.834020833333334 2.3958333333333335 13.416666666666668c0 -2.5854395833333332 0.10343770833333334 -4.467510416666666 0.21182041666666668 -5.729922916666667 0.10471708333333335 -1.2196708333333333 1.0569122916666667 -2.1305666666666667 2.2783129166666667 -2.2130791666666667C6.3641958333333335 5.373854166666667 8.687004166666668 5.270833333333334 11.979166666666668 5.270833333333334c3.2921625 0 5.6149708333333335 0.10302083333333334 7.0932 0.20283125000000002 0.36651458333333337 0.02477291666666667 0.7087354166666667 0.12410416666666668 1.0111375 0.2840020833333333 -0.16406666666666667 -0.9921912500000001 -0.31955625000000004 -1.8018870833333334 -0.45463333333333333 -2.442585625 -0.24509375 -1.1622283333333334 -1.2750625 -1.9346210416666667 -2.47408125 -1.8742795833333334 -1.4488083333333335 0.07291479166666667 -3.7170395833333334 0.24217083333333334 -6.912266666666667 0.6301377083333334 -3.1951791666666667 0.387966875 -5.437439583333333 0.7663791666666666 -6.8610581250000005 1.0422402083333335 -1.1781510416666667 0.22829416666666666 -1.9886087500000003 1.2241558333333333 -1.9420577083333335 2.4105102083333336 0.0508875 1.2969125 0.1864485416666667 3.2579979166666666 0.5223347916666667 5.963229166666667 0.14749229166666666 1.1879020833333334 0.29736125 2.2273104166666666 0.44176291666666667 3.127185416666667Z" strokeWidth="1." />
											<path id="Rectangle 1103" fill="#ffffff" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
											<path id="Rectangle 1100" fill="#ffffff" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.608697916666667 3.3036193750000002c-0.24475833333333336 -1.1551366666666667 -1.273625 -1.9228095833333332 -2.4713020833333332 -1.8628370833333334 -1.4472270833333334 0.072464375 -3.7129187500000005 0.24069020833333335 -6.9046 0.62629 -3.1916812500000002 0.38559979166666664 -5.431450000000001 0.7616977083333334 -6.8534825 1.035876875 -1.1768477083333335 0.22689979166666668 -1.9864045833333333 1.216685625 -1.939911041666667 2.3957758333333334 0.05083479166666667 1.2890541666666666 0.18624250000000003 3.2381124999999997 0.5217597916666666 5.926860416666667 0.15179041666666668 1.2164125000000001 0.30610604166666666 2.276185416666667 0.4543697916666667 3.188997916666667" strokeWidth="1.5" />
											<path id="Rectangle 1099" stroke="#2859c5" strokeLinejoin="round" d="M2.60765375 19.146589583333334c0.10471229166666667 1.2196708333333333 1.0569122916666667 2.1305666666666667 2.2783129166666667 2.2130791666666667C6.3641958333333335 21.459479166666664 8.687004166666668 21.5625 11.979166666666668 21.5625c3.2921625 0 5.6149708333333335 -0.10302083333333334 7.0932 -0.20283125000000002 1.2213958333333335 -0.0825125 2.1735958333333336 -0.9934083333333333 2.27829375 -2.2130791666666667C21.45904791666667 17.884177083333334 21.5625 16.00210625 21.5625 13.416666666666668s-0.10345208333333335 -4.467510416666666 -0.21183958333333333 -5.729922916666667c-0.10469791666666667 -1.2196708333333333 -1.0568979166666668 -2.1305666666666667 -2.27829375 -2.2130791666666667C17.594137500000002 5.373854166666667 15.271329166666668 5.270833333333334 11.979166666666668 5.270833333333334c-3.2921625 0 -5.6149708333333335 0.10302083333333334 -7.0932 0.20283125000000002 -1.221400625 0.0825125 -2.1735958333333336 0.9934083333333333 -2.2783129166666667 2.2130791666666667C2.499271041666667 8.949156250000001 2.3958333333333335 10.831227083333333 2.3958333333333335 13.416666666666668s0.10343770833333334 4.467510416666666 0.21182041666666668 5.729922916666667Z" strokeWidth="1.5" />
											<path id="Vector 1469" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 9.104166666666668h-3.8333333333333335" strokeWidth="1.5" />
											<path id="Vector 1471" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 14.854166666666668h-3.8333333333333335" strokeWidth="1.5" />
											<path id="Vector 1472" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M18.6875 17.729166666666668H5.270833333333334" strokeWidth="1.5" />
											<path id="Vector 1470" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 11.979166666666668h-3.8333333333333335" strokeWidth="1.5" />
											<path id="Rectangle 1096" fill="#ffffff" stroke="#2859c5" strokeLinejoin="round" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
										</g>
									</svg>
									<span className="link-text ml-3">
										Auto Blog
									</span>
									<span className="uncollapsed-tag tag is-info is-light is-rounded" style={{marginLeft:'5.2em'}}>
										Upgrade ⚡
									</span>
								</NavLink>
								:
								<NavLink to={pageURL['addAutomation']}
									onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? `is-active` : ""}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
										<g>
											<path id="Subtract" fill="#ffffff" d="M2.4035047916666668 14.614104166666667C2.3985310416666668 14.233070833333334 2.3958333333333335 13.834020833333334 2.3958333333333335 13.416666666666668c0 -2.5854395833333332 0.10343770833333334 -4.467510416666666 0.21182041666666668 -5.729922916666667 0.10471708333333335 -1.2196708333333333 1.0569122916666667 -2.1305666666666667 2.2783129166666667 -2.2130791666666667C6.3641958333333335 5.373854166666667 8.687004166666668 5.270833333333334 11.979166666666668 5.270833333333334c3.2921625 0 5.6149708333333335 0.10302083333333334 7.0932 0.20283125000000002 0.36651458333333337 0.02477291666666667 0.7087354166666667 0.12410416666666668 1.0111375 0.2840020833333333 -0.16406666666666667 -0.9921912500000001 -0.31955625000000004 -1.8018870833333334 -0.45463333333333333 -2.442585625 -0.24509375 -1.1622283333333334 -1.2750625 -1.9346210416666667 -2.47408125 -1.8742795833333334 -1.4488083333333335 0.07291479166666667 -3.7170395833333334 0.24217083333333334 -6.912266666666667 0.6301377083333334 -3.1951791666666667 0.387966875 -5.437439583333333 0.7663791666666666 -6.8610581250000005 1.0422402083333335 -1.1781510416666667 0.22829416666666666 -1.9886087500000003 1.2241558333333333 -1.9420577083333335 2.4105102083333336 0.0508875 1.2969125 0.1864485416666667 3.2579979166666666 0.5223347916666667 5.963229166666667 0.14749229166666666 1.1879020833333334 0.29736125 2.2273104166666666 0.44176291666666667 3.127185416666667Z" strokeWidth="1." />
											<path id="Rectangle 1103" fill="#ffffff" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
											<path id="Rectangle 1100" fill="#ffffff" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.608697916666667 3.3036193750000002c-0.24475833333333336 -1.1551366666666667 -1.273625 -1.9228095833333332 -2.4713020833333332 -1.8628370833333334 -1.4472270833333334 0.072464375 -3.7129187500000005 0.24069020833333335 -6.9046 0.62629 -3.1916812500000002 0.38559979166666664 -5.431450000000001 0.7616977083333334 -6.8534825 1.035876875 -1.1768477083333335 0.22689979166666668 -1.9864045833333333 1.216685625 -1.939911041666667 2.3957758333333334 0.05083479166666667 1.2890541666666666 0.18624250000000003 3.2381124999999997 0.5217597916666666 5.926860416666667 0.15179041666666668 1.2164125000000001 0.30610604166666666 2.276185416666667 0.4543697916666667 3.188997916666667" strokeWidth="1.5" />
											<path id="Rectangle 1099" stroke="#2859c5" strokeLinejoin="round" d="M2.60765375 19.146589583333334c0.10471229166666667 1.2196708333333333 1.0569122916666667 2.1305666666666667 2.2783129166666667 2.2130791666666667C6.3641958333333335 21.459479166666664 8.687004166666668 21.5625 11.979166666666668 21.5625c3.2921625 0 5.6149708333333335 -0.10302083333333334 7.0932 -0.20283125000000002 1.2213958333333335 -0.0825125 2.1735958333333336 -0.9934083333333333 2.27829375 -2.2130791666666667C21.45904791666667 17.884177083333334 21.5625 16.00210625 21.5625 13.416666666666668s-0.10345208333333335 -4.467510416666666 -0.21183958333333333 -5.729922916666667c-0.10469791666666667 -1.2196708333333333 -1.0568979166666668 -2.1305666666666667 -2.27829375 -2.2130791666666667C17.594137500000002 5.373854166666667 15.271329166666668 5.270833333333334 11.979166666666668 5.270833333333334c-3.2921625 0 -5.6149708333333335 0.10302083333333334 -7.0932 0.20283125000000002 -1.221400625 0.0825125 -2.1735958333333336 0.9934083333333333 -2.2783129166666667 2.2130791666666667C2.499271041666667 8.949156250000001 2.3958333333333335 10.831227083333333 2.3958333333333335 13.416666666666668s0.10343770833333334 4.467510416666666 0.21182041666666668 5.729922916666667Z" strokeWidth="1.5" />
											<path id="Vector 1469" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 9.104166666666668h-3.8333333333333335" strokeWidth="1.5" />
											<path id="Vector 1471" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 14.854166666666668h-3.8333333333333335" strokeWidth="1.5" />
											<path id="Vector 1472" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M18.6875 17.729166666666668H5.270833333333334" strokeWidth="1.5" />
											<path id="Vector 1470" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 11.979166666666668h-3.8333333333333335" strokeWidth="1.5" />
											<path id="Rectangle 1096" fill="#ffffff" stroke="#2859c5" strokeLinejoin="round" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
										</g>
									</svg>
									<span className="link-text ml-4">Auto Blog</span>
								</NavLink>
							}
						</li>

						{/* ------------------------- INDEXATION ------------------------- */}
						<li className={"mt-2 indexation category-menu"}>
							{props.currentPlanName === "Trial" ?
								<NavLink to={pageURL['manageSubscription']} className={"upgrade"} onClick={checkForUnsavedChanges}>
									<div className="category">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
											<g>
												<path id="hover" fill="#fff" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
												<path id="Ellipse 32" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M44.788 21C43.332 10.823 34.58 3 24 3S4.67 10.823 3.213 21" strokeWidth="3" />
												<path id="Ellipse 582" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M27 44.787a20.847 20.847 0 0 0 4.798 -1.282M21 44.787a20.81 20.81 0 0 1 -4.829 -1.295M44.788 27a20.855 20.855 0 0 1 -1.283 4.797M3.213 27c0.239 1.67 0.674 3.278 1.282 4.797m6.515 8.704a21.125 21.125 0 0 1 -3.531 -3.536m33.043 0a21.126 21.126 0 0 1 -3.558 3.557" strokeWidth="3" />
												<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
											</g>
										</svg>
										<span className="link-text ml-4">
											Fast Google Index
										</span>
									</div>
									<span className="uncollapsed-tag tag is-info is-light is-rounded">
										Upgrade ⚡
									</span>
								</NavLink>
								:
								<NavLink to={pageURL['indexation']}
									onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active " : ""}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="hover" fill="#fff" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
											<path id="Ellipse 32" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M44.788 21C43.332 10.823 34.58 3 24 3S4.67 10.823 3.213 21" strokeWidth="3" />
											<path id="Ellipse 582" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M27 44.787a20.847 20.847 0 0 0 4.798 -1.282M21 44.787a20.81 20.81 0 0 1 -4.829 -1.295M44.788 27a20.855 20.855 0 0 1 -1.283 4.797M3.213 27c0.239 1.67 0.674 3.278 1.282 4.797m6.515 8.704a21.125 21.125 0 0 1 -3.531 -3.536m33.043 0a21.126 21.126 0 0 1 -3.558 3.557" strokeWidth="3" />
											<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
										</g>
									</svg>
									<span className="link-text ml-4">Fast Google Index</span>
								</NavLink>
							}
						</li>

						{/* ------------------------- INTEGRATIONS ------------------------- */}
						<li className={"mt-2 indexation category-menu"}>
							{props.currentPlanName === "Trial" ?
								<NavLink to={pageURL['manageSubscription']} className={"upgrade"} onClick={checkForUnsavedChanges}>
									<div className="category">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
											<g>
												<path id="Subtract" fill="#ffffff" d="M20.376 37.884c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74a61.832 61.832 0 0 1 -3.625 0.115c-1.248 0 -2.497 -0.048 -3.624 -0.115Z" strokeWidth="3" />
												<path id="hover" fill="#fff" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
												<path id="Subtract_2" fill="#ffffff" d="M29.144 5.742c0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269a157.085 157.085 0 0 0 -5.986 -0.222C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258Z" strokeWidth="3" />
												<path id="Subtract_3" fill="#ffffff" d="M18.856 5.742c-0.066 -1.435 -0.864 -2.658 -2.299 -2.729a11.322 11.322 0 0 0 -1.114 0c-1.435 0.07 -2.233 1.294 -2.299 2.73C13.071 7.325 13 9.916 13 14c0 0.814 0.003 1.57 0.008 2.269 1.615 -0.094 3.589 -0.174 5.986 -0.222 0.004 -0.637 0.006 -1.318 0.006 -2.047 0 -4.083 -0.071 -6.674 -0.144 -8.258Z" strokeWidth="3" />
												<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
												<path id="Subtract_4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.008 16.269C13.003 15.569 13 14.814 13 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.436 0.07 2.233 1.294 2.299 2.73C18.929 7.325 19 9.916 19 14c0 0.729 -0.002 1.41 -0.006 2.047" strokeWidth="3" />
												<path id="Subtract_5" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M29.006 16.047C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269" strokeWidth="3" />
												<path id="Subtract_6" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.375 37.885c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74" strokeWidth="3" />
											</g>
										</svg>
										<span className="link-text ml-4">
											Integration
										</span>
									</div>
									<span className="uncollapsed-tag tag is-info is-light is-rounded">
										Upgrade ⚡
									</span>
								</NavLink>
								:
								<NavLink to={pageURL['integration']} className={({ isActive }) => (isActive && window.location.href.endsWith("?tab=integration")) ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										let interval = setInterval(() => {
											const integrationTab = document.getElementById("integration-tab");
											if (integrationTab) {
												integrationTab.click();
												clearInterval(interval);
											}
										}, 500);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="Subtract" fill="#ffffff" d="M20.376 37.884c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74a61.832 61.832 0 0 1 -3.625 0.115c-1.248 0 -2.497 -0.048 -3.624 -0.115Z" strokeWidth="3" />
											<path id="hover" fill="#fff" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
											<path id="Subtract_2" fill="#ffffff" d="M29.144 5.742c0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269a157.085 157.085 0 0 0 -5.986 -0.222C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258Z" strokeWidth="3" />
											<path id="Subtract_3" fill="#ffffff" d="M18.856 5.742c-0.066 -1.435 -0.864 -2.658 -2.299 -2.729a11.322 11.322 0 0 0 -1.114 0c-1.435 0.07 -2.233 1.294 -2.299 2.73C13.071 7.325 13 9.916 13 14c0 0.814 0.003 1.57 0.008 2.269 1.615 -0.094 3.589 -0.174 5.986 -0.222 0.004 -0.637 0.006 -1.318 0.006 -2.047 0 -4.083 -0.071 -6.674 -0.144 -8.258Z" strokeWidth="3" />
											<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
											<path id="Subtract_4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.008 16.269C13.003 15.569 13 14.814 13 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.436 0.07 2.233 1.294 2.299 2.73C18.929 7.325 19 9.916 19 14c0 0.729 -0.002 1.41 -0.006 2.047" strokeWidth="3" />
											<path id="Subtract_5" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M29.006 16.047C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269" strokeWidth="3" />
											<path id="Subtract_6" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.375 37.885c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74" strokeWidth="3" />
										</g>
									</svg>
									<span className="link-text ml-4">Integration</span>
								</NavLink>
							}
						</li>

						{/* ------------------------- MANAGE SUBSCRIPTION ------------------------- */}
						<li className="mt-2 category-menu pricing-plan">
							<NavLink to={pageURL['manageSubscription']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Ellipse 20" fill="#ffffff" d="M24 45c11.598 0 21 -9.402 21 -21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21Z" strokeWidth="3" />
										<path id="hover" fill="#ffffff" d="M22.225 12.423c0.746 -1.439 2.804 -1.439 3.55 0l2.811 5.418 5.959 1.174c1.523 0.3 2.138 2.145 1.1 3.3l-4.225 4.698 0.776 6.288c0.196 1.584 -1.45 2.747 -2.878 2.035L24 32.68l-5.317 2.655c-1.428 0.712 -3.074 -0.451 -2.879 -2.035l0.776 -6.288 -4.225 -4.699c-1.038 -1.154 -0.422 -3 1.101 -3.3l5.959 -1.173 2.81 -5.418Z" strokeWidth="3" />
										<path id="Ellipse 19" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24 3a21 21 0 1 0 0 42 21 21 0 1 0 0 -42" strokeWidth="3" />
										<path id="Star 4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M22.224 12.423c0.747 -1.439 2.805 -1.439 3.551 0l2.81 5.418 5.96 1.174c1.522 0.3 2.138 2.145 1.1 3.3l-4.225 4.698 0.776 6.288c0.195 1.584 -1.45 2.747 -2.878 2.035L24 32.68l-5.318 2.655c-1.427 0.712 -3.073 -0.451 -2.878 -2.035l0.776 -6.288 -4.225 -4.699c-1.038 -1.154 -0.422 -3 1.1 -3.3l5.96 -1.173 2.81 -5.418Z" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Subscription</span>
							</NavLink>
						</li>

						{/* ------------------------- SETTINGS ------------------------- */}
						<li className="mt-2 category-menu settings">
							<NavLink to={pageURL['settings']} className={({ isActive }) => (isActive && !window.location.href.endsWith("?tab=integration")) ? "is-active settings" : "settings"}
								onClick={(e) => {
									checkForUnsavedChanges(e);
									let interval = setInterval(() => {
										const imagesTab = document.getElementById("images-tab");
										if (imagesTab) {
											imagesTab.click();
											clearInterval(interval);
										}
									}, 500);
								}}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Ellipse 20" fill="#fff" d="M45 24c0 11.598 -9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3s21 9.402 21 21Z" strokeWidth="3" />
										<path id="hover" fill="#ffffff" d="M18.402 44.246v-4.94c0 -1.59 -0.974 -2.987 -2.287 -3.883a13.984 13.984 0 0 1 -6.113 -11.568c0 -4.99 2.612 -9.37 6.542 -11.85 1.186 -0.747 2.584 0.22 2.583 1.62 -0.003 2.92 0.04 5.856 0.17 8.154 0.022 0.399 0.167 0.78 0.439 1.072 0.619 0.666 1.918 1.896 3.641 2.633 0.398 0.17 0.849 0.17 1.247 0 1.677 -0.718 2.953 -1.903 3.59 -2.579 0.306 -0.324 0.453 -0.756 0.453 -1.202v-8.222c0 -1.38 1.37 -2.335 2.552 -1.622 4.063 2.45 6.78 6.906 6.78 11.996 0 4.806 -2.422 9.047 -6.112 11.567 -1.313 0.897 -2.286 2.294 -2.286 3.884v4.939A21.02 21.02 0 0 1 24 45a21.02 21.02 0 0 1 -5.598 -0.754Z" strokeWidth="3" />
										<path id="Ellipse 19" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3 24a21 21 0 1 0 42 0 21 21 0 1 0 -42 0" strokeWidth="3" />
										<path id="Intersect_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M18.402 44.246v-4.94c0 -1.59 -0.974 -2.987 -2.287 -3.883a13.984 13.984 0 0 1 -6.112 -11.568c0 -4.99 2.611 -9.37 6.542 -11.85 1.185 -0.747 2.583 0.22 2.582 1.62 -0.003 2.92 0.04 5.856 0.17 8.154 0.022 0.399 0.167 0.78 0.439 1.072 0.62 0.666 1.918 1.896 3.641 2.633 0.398 0.17 0.849 0.17 1.247 0 1.677 -0.718 2.953 -1.903 3.59 -2.579 0.306 -0.324 0.453 -0.756 0.453 -1.202v-8.222c0 -1.38 1.37 -2.335 2.553 -1.622 4.063 2.45 6.78 6.906 6.78 11.996 0 4.806 -2.422 9.047 -6.113 11.567 -1.312 0.897 -2.286 2.294 -2.286 3.884v4.939" strokeWidth="3" />
									</g>
								</svg>
								<span className="link-text ml-4">Article Settings</span>
							</NavLink>
						</li>

						{/* ------------------------- Free BackLinks ------------------------- */}
						<li className="mt-2 indexation category-menu">
							<NavLink to={"/backlinks"} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
									<g>
										<path id="hover" fill="#fff" d="M19.35785416666667 3.6646666666666667c-2.955020833333333 -2.954541666666667 -7.745250000000001 -2.954541666666667 -10.699791666666666 0l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.1408958333333332a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8562708333333333 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.141375c2.954541666666667 -2.954541666666667 2.954541666666667 -7.745250000000001 0 -10.699791666666666Z" stroke-width="1"></path>

										<path id="hover" fill="#ffffff" d="M3.6641875 19.357375c-2.954541666666667 -2.954541666666667 -2.954541666666667 -7.745250000000001 0 -10.699791666666666l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8562708333333333a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375a2.5218541666666665 2.5218541666666665 0 0 0 3.5669166666666667 3.5664375l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375c-2.954541666666667 2.954541666666667 -7.745250000000001 2.954541666666667 -10.699791666666666 0Z" stroke-width="1"></path>
										<path id="Rectangle 1673" fill="#ffffff" d="M7.230625 15.790458333333333a2.5218541666666665 2.5218541666666665 0 0 1 0 -3.5664375l4.993875 -4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-4.992916666666667 4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 -3.5669166666666667 0Z" stroke-width="1"></path><path id="Rectangle 1672" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M7.231104166666666 15.790458333333333a2.5218541666666665 2.5218541666666665 0 0 1 0 -3.5664375l4.992916666666667 -4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 3.5669166666666667 3.5664375l-4.992916666666667 4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 -3.5669166666666667 0Z" stroke-width="1"></path>
										<path id="Union_3" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M3.6641875 19.357375c-2.954541666666667 -2.954541666666667 -2.954541666666667 -7.745250000000001 0 -10.699791666666666l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8562708333333333a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375a2.5218541666666665 2.5218541666666665 0 0 0 3.5669166666666667 3.5664375l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375c-2.954541666666667 2.954541666666667 -7.745250000000001 2.954541666666667 -10.699791666666666 0Z" stroke-width="1"></path>
										<path id="Union_4" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M19.35785416666667 3.6646666666666667c-2.955020833333333 -2.954541666666667 -7.745250000000001 -2.954541666666667 -10.699791666666666 0l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.1408958333333332a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8562708333333333 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.141375c2.954541666666667 -2.954541666666667 2.954541666666667 -7.745250000000001 0 -10.699791666666666Z" stroke-width="1">
										</path>
									</g>
								</svg>
								<span className="link-text ml-4">Free Backlinks</span>
							</NavLink>
						</li>

						{/* ------------------------- Affiliate Program  ------------------------- */}
						<li className="mt-2 subcategory-menu pricing-plan">
							<NavLink to={"https://abun.com/affiliate"} target="_blank"
								onClick={checkForUnsavedChanges}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
									<g>
										<path id="Ellipse 23" fill="#ffffff" d="M11.5 1.4375c5.557375 0 10.0625 4.505125 10.0625 10.0625s-4.505125 10.0625 -10.0625 10.0625S1.4375 17.057375 1.4375 11.5 5.942625 1.4375 11.5 1.4375Z" strokeWidth="1" />
										<path id="Ellipse 22" fill="#8fbffa" d="M11.5 3.8333333333333335c4.234395833333333 0 7.666666666666667 3.4322708333333334 7.666666666666667 7.666666666666667s-3.4322708333333334 7.666666666666667 -7.666666666666667 7.666666666666667S3.8333333333333335 15.734395833333336 3.8333333333333335 11.5 7.265604166666667 3.8333333333333335 11.5 3.8333333333333335Z" strokeWidth="1" />
										<path id="Ellipse 19" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 1.4375c5.557375 0 10.0625 4.505125 10.0625 10.0625s-4.505125 10.0625 -10.0625 10.0625S1.4375 17.057375 1.4375 11.5 5.942625 1.4375 11.5 1.4375Z" strokeWidth="1.5" />
										{/* <path id="Ellipse 21" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 3.8333333333333335c4.234395833333333 0 7.666666666666667 3.4322708333333334 7.666666666666667 7.666666666666667s-3.4322708333333334 7.666666666666667 -7.666666666666667 7.666666666666667S3.8333333333333335 15.734395833333336 3.8333333333333335 11.5 7.265604166666667 3.8333333333333335 11.5 3.8333333333333335Z" strokeWidth="1.5"/> */}
										<path id="Vector" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.65625 9.079729166666668S12.79375 8.418958333333334 11.5 8.418958333333334c-1.078125 0 -2.15625 0.6602916666666666 -2.15625 1.5405208333333333 0 2.199854166666667 4.3125 0.87975 4.3125 3.0800833333333335 0 0.8802291666666667 -1.078125 1.5400416666666668 -2.15625 1.5400416666666668 -1.2937500000000002 0 -2.15625 -0.6598125 -2.15625 -0.6598125" strokeWidth="1.5" />
										<path id="Vector 837" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 8.4194375V7.1875" strokeWidth="1.5" />
										<path id="Vector 838" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 15.8125v-1.2314583333333333" strokeWidth="1.5" />
									</g>
								</svg>
								<span className="link-text ml-4">Affiliate Program </span>
							</NavLink>
						</li>

						{/* ------------------------- Feature Request Form ------------------------- */}
						{/* <li className="mt-2 subcategory-menu pricing-plan"> */}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							{/* <a onClick={() => setFeatureRequestModalActive(true)}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.75 -0.75 24 24" height="24" width="24">
									<g>
										<path id="Union" d="M3.8566124999999998 18.550593749999997c-1.1900765625 -0.07621875 -2.1283499999999997 -0.953859375 -2.2371140625 -2.1414375C1.5097593749999998 15.210984374999999 1.40625 13.438640625 1.40625 11.015625c0 -2.423015625 0.103509375 -4.195359375000001 0.2132484375 -5.39353125 0.1087640625 -1.187559375 1.0470421875 -2.065228125 2.2371140625 -2.1414515625C5.36896875 3.3837796875 7.787531249999999 3.28125 11.25 3.28125s5.88103125 0.10252968750000001 7.39340625 0.199396875c1.1900625 0.0762234375 2.1283125 0.9538875 2.237109375 2.1414468749999997C20.990250000000003 6.820265624999999 21.09375 8.592609375 21.09375 11.015625c0 2.423015625 -0.1035 4.195359375000001 -0.213234375 5.39353125 -0.108796875 1.187578125 -1.047046875 2.06521875 -2.237109375 2.1414375 -1.4619843750000001 0.09365625 -3.770765625 0.1925625 -7.05084375 0.19907812500000002l-3.0136875 2.5831874999999997C7.9707187500000005 21.854109375 7.03125 21.422015625 7.03125 20.6210625v-1.928203125c-1.3245 -0.038343749999999996 -2.37594375 -0.091078125 -3.1746375000000002 -0.142265625Z" strokeWidth="1.5" />
										<path id="Rectangle 1845" fill="#ffffff" d="M13.033078125 1.4044874999999999c-0.031265625 -0.259715625 -0.280453125 -0.380925 -0.5107968749999999 -0.25696875 -0.32071875 0.1725609375 -0.830859375 0.47074687499999995 -1.559015625 0.962034375 -0.597328125 0.402984375 -0.974578125 0.71934375 -1.21059375 0.9490921875 -0.27281249999999996 0.2655703125 -0.27285937499999996 0.6487640625000001 -0.000046875 0.9143484374999999 0.23601562499999998 0.2297671875 0.613265625 0.5461265625 1.210640625 0.94906875 0.728109375 0.49115625 1.23825 0.7893281249999999 1.558921875 0.9619218749999999 0.230390625 0.12398437500000001 0.4796250000000001 0.002765625 0.510890625 -0.25696875 0.045328125 -0.376546875 0.091921875 -1.02725625 0.091921875 -2.1112125 0 -1.08406875 -0.04659375 -1.7348249999999998 -0.091921875 -2.1113156249999996Z" strokeWidth="1.5" />
										<g>
											<path d="M12.65625 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
											<path d="M17.8125 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
											<path d="M7.5 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
										</g>
										<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.125 3.291778125c2.480390625 0.028537499999999997 4.295625 0.11055937499999999 5.51840625 0.188878125 1.1900625 0.0762234375 2.1283125 0.9538921874999999 2.237109375 2.1414375C20.990250000000003 6.820265624999999 21.09375 8.592609375 21.09375 11.015625c0 2.423015625 -0.1035 4.1954062500000004 -0.213234375 5.3935781249999994 -0.108796875 1.18753125 -1.047046875 2.0651718750000003 -2.237109375 2.1414375 -1.4619843750000001 0.093609375 -3.770765625 0.1925625 -7.05084375 0.19903125l-3.0136875 2.5831874999999997C7.9707187500000005 21.854109375 7.03125 21.422015625 7.03125 20.6210625v-1.9281562500000002c-1.3245 -0.038390625 -2.37594375 -0.091125 -3.1746375000000002 -0.142265625 -1.1900765625 -0.076265625 -2.1283499999999997 -0.9539062500000001 -2.2371140625 -2.1414375C1.5097593749999998 15.211031250000001 1.40625 13.438640625 1.40625 11.015625c0 -2.42296875 0.103509375 -4.195359375000001 0.2132484375 -5.39353125 0.1087640625 -1.1875453125 1.0470421875 -2.0652140625 2.2371140625 -2.1414375C4.65530625 3.429496875 5.70675 3.3767625 7.03125 3.3383859375" strokeWidth="1.5" />
										<path id="Rectangle 1846" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M13.033078125 1.4044874999999999c-0.031265625 -0.259715625 -0.280453125 -0.380925 -0.5107968749999999 -0.25696875 -0.32071875 0.1725609375 -0.830859375 0.47074687499999995 -1.559015625 0.962034375 -0.597328125 0.402984375 -0.974578125 0.71934375 -1.21059375 0.9490921875 -0.27281249999999996 0.2655703125 -0.27285937499999996 0.6487640625000001 -0.000046875 0.9143484374999999 0.23601562499999998 0.2297671875 0.613265625 0.5461265625 1.210640625 0.94906875 0.728109375 0.49115625 1.23825 0.7893281249999999 1.558921875 0.9619218749999999 0.230390625 0.12398437500000001 0.4796250000000001 0.002765625 0.510890625 -0.25696875 0.045328125 -0.376546875 0.091921875 -1.02725625 0.091921875 -2.1112125 0 -1.08406875 -0.04659375 -1.7348249999999998 -0.091921875 -2.1113156249999996Z" strokeWidth="1.5" />
										<path id="Vector_2" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M7.5 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
										<path id="Vector_3" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M12.65625 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
										<path id="Vector_4" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M17.8125 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
									</g>
								</svg>
								<span className="link-text ml-4">Feature Request</span>
							</a>
						</li> */}

						{/* ------------------------- Tutorials ------------------------- */}
						<li className="mt-2 subcategory-menu pricing-plan">
							<NavLink to={pageURL['tutorials']} className={({ isActive }) => (isActive && !window.location.href.endsWith("?tab=integration")) ? "is-active settings" : "settings"}
								onClick={(e) => {
									checkForUnsavedChanges(e);
									let interval = setInterval(() => {
										const imagesTab = document.getElementById("images-tab");
										if (imagesTab) {
											imagesTab.click();
											clearInterval(interval);
										}
									}, 500);
								}}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Rectangle 1105" fill="#8fbffa" d="M3.539 8.257c0.208 -2.555 2.163 -4.51 4.718 -4.718C11.485 3.277 16.636 3 24 3c7.364 0 12.515 0.277 15.743 0.539 2.555 0.208 4.51 2.163 4.718 4.718C44.723 11.485 45 16.636 45 24c0 7.364 -0.277 12.515 -0.539 15.743 -0.208 2.555 -2.163 4.51 -4.718 4.718C36.515 44.723 31.364 45 24 45c-7.364 0 -12.515 -0.277 -15.743 -0.539 -2.555 -0.208 -4.51 -2.163 -4.718 -4.718C3.277 36.515 3 31.364 3 24c0 -7.364 0.277 -12.515 0.539 -15.743Z" strokeWidth="3"></path>
										<path id="Intersect" fill="#ffffff" d="M44.806 14H3.196c0.098 -2.345 0.222 -4.25 0.344 -5.743 0.207 -2.555 2.163 -4.51 4.718 -4.718C11.486 3.277 16.637 3 24 3c7.364 0 12.514 0.277 15.743 0.539 2.555 0.208 4.51 2.163 4.718 4.718 0.121 1.494 0.245 3.398 0.344 5.743Z" strokeWidth="3"></path>
										<path id="Rectangle 1106" fill="#ffffff" d="M17.353 23.873c0.25 -1.73 1.775 -2.714 3.405 -2.084 1.144 0.443 2.686 1.133 4.713 2.21 1.938 1.03 3.432 1.941 4.56 2.695 1.758 1.175 1.758 3.435 0 4.61 -1.128 0.754 -2.622 1.665 -4.56 2.695 -2.027 1.077 -3.569 1.767 -4.713 2.21 -1.63 0.63 -3.155 -0.354 -3.405 -2.084A35.992 35.992 0 0 1 17 28.999c0 -2.078 0.163 -3.81 0.353 -5.126Z" strokeWidth="3"></path>
										<path id="Rectangle 1104" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3.539 8.257c0.208 -2.555 2.163 -4.51 4.718 -4.718C11.485 3.277 16.636 3 24 3c7.364 0 12.515 0.277 15.743 0.539 2.555 0.208 4.51 2.163 4.718 4.718C44.723 11.485 45 16.636 45 24c0 7.364 -0.277 12.515 -0.539 15.743 -0.208 2.555 -2.163 4.51 -4.718 4.718C36.515 44.723 31.364 45 24 45c-7.364 0 -12.515 -0.277 -15.743 -0.539 -2.555 -0.208 -4.51 -2.163 -4.718 -4.718C3.277 36.515 3 31.364 3 24c0 -7.364 0.277 -12.515 0.539 -15.743Z" strokeWidth="3"></path>
										<path id="Rectangle 128" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M17.353 23.874c0.25 -1.73 1.775 -2.714 3.405 -2.084 1.144 0.443 2.686 1.133 4.713 2.21 1.938 1.03 3.432 1.941 4.56 2.695 1.758 1.175 1.758 3.435 0 4.61 -1.128 0.754 -2.622 1.665 -4.56 2.695 -2.027 1.077 -3.569 1.767 -4.713 2.21 -1.63 0.63 -3.155 -0.354 -3.405 -2.084A35.992 35.992 0 0 1 17 29c0 -2.078 0.163 -3.81 0.353 -5.126Z" strokeWidth="3"></path>
										<path id="Intersect_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M44.806 14H3.196" strokeWidth="3"></path>
										<path id="Vector 167" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m20.289 3.15 -8.008 10.677" strokeWidth="3"></path>
										<path id="Vector 168" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m36.717 3.378 -7.852 10.469" strokeWidth="3"></path>
									</g>
								</svg>
								<span className="link-text ml-4">Tutorials</span>
							</NavLink>
						</li>

						{/* ------------------------- Affiliate Program  ------------------------- */}
						<li className="mt-2 subcategory-menu pricing-plan">
							<NavLink to={pageURL['get-demo']} className={({ isActive }) => isActive ? "is-active" : ""}
								onClick={checkForUnsavedChanges}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
									<g>
										<path id="Union" fill="#8fbffa" d="M7.471 38.244c-2.31 -0.354 -3.974 -2.23 -4.147 -4.56 -0.167 -2.24 -0.324 -5.47 -0.324 -9.688 0 -4.218 0.157 -7.447 0.324 -9.688 0.173 -2.33 1.837 -4.206 4.147 -4.56 2.447 -0.376 6.022 -0.752 10.529 -0.752s8.082 0.376 10.529 0.752c2.31 0.354 3.974 2.23 4.147 4.56 0.09 1.2 0.176 2.684 0.237 4.45 2.066 -1.397 4.97 -3.124 8.21 -4.343 1.574 -0.592 3.205 0.434 3.417 2.103 0.233 1.83 0.46 4.404 0.46 7.478s-0.227 5.648 -0.46 7.478c-0.212 1.669 -1.843 2.695 -3.417 2.103 -3.24 -1.219 -6.144 -2.946 -8.21 -4.343 -0.06 1.767 -0.147 3.25 -0.237 4.45 -0.173 2.33 -1.837 4.206 -4.147 4.56 -2.447 0.376 -6.022 0.752 -10.529 0.752s-8.082 -0.376 -10.529 -0.752Z" stroke-width="3"></path>
										<path id="Union_2" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M7.471 38.248c-2.31 -0.354 -3.974 -2.23 -4.147 -4.56C3.157 31.448 3 28.218 3 24c0 -4.218 0.157 -7.447 0.324 -9.688 0.173 -2.33 1.837 -4.206 4.147 -4.56C9.918 9.376 13.493 9 18 9s8.082 0.376 10.529 0.752c2.31 0.354 3.974 2.23 4.147 4.56 0.09 1.2 0.176 2.684 0.237 4.45 2.066 -1.397 4.97 -3.124 8.21 -4.343 1.574 -0.592 3.205 0.434 3.417 2.103 0.233 1.83 0.46 4.404 0.46 7.478s-0.227 5.648 -0.46 7.478c-0.212 1.669 -1.843 2.695 -3.417 2.103 -3.24 -1.219 -6.144 -2.946 -8.21 -4.343 -0.06 1.767 -0.147 3.25 -0.237 4.45 -0.173 2.33 -1.837 4.206 -4.147 4.56C26.082 38.624 22.507 39 18 39s-8.082 -0.376 -10.529 -0.752Z" stroke-width="3"></path>
									</g>
								</svg>
								<span className="link-text ml-4">Get Demo</span>
							</NavLink>
						</li>
					</ul>
				</div>

				{/* ------------------------- PROFILE PAGE ------------------------- */}
				<div className={"sidebar-footer"}>
					<ul className={"menu-list"}>
						<li className="category-menu profile">
							<NavLink to={pageURL['profile']}
								onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
									<g>
										<path id="hover" fill="#ffffff" d="M18.48495625 1.2330395833333334C18.91635 1.1847875 19.35814166666667 1.3280583333333333 19.621635416666667 1.6730104166666666C21.055014583333335 3.5492833333333333 21.055014583333335 7.9626 21.055014583333335 10.995964583333334C21.055014583333335 11.211254166666668 21.044329166666667 11.418877083333333 21.022622916666666 11.618689583333333C21.358231250000003 12.622735416666668 21.54501041666667 13.694583333333332 21.54501041666667 14.8128625C21.54501041666667 16.072927083333333 21.5449625 21.776064583333334 11.254954166666668 21.776064583333334C0.9649937500000001 21.776064583333334 0.9649937500000001 16.072927083333333 0.9649937500000001 14.8128625C0.9649937500000001 13.694583333333332 1.1517729166666668 12.6226875 1.48738125 11.618641666666667C1.465675 11.418877083333333 1.4549895833333335 11.211254166666668 1.4549895833333335 10.995964583333334C1.4549895833333335 7.9626 1.4549895833333335 3.5492833333333333 2.88836875 1.6730104166666666C3.1519104166666665 1.3280583333333333 3.5936541666666666 1.1847875 4.025047916666667 1.2330395833333334C6.1307937500000005 1.46855 7.767675 2.8007770833333336 8.9472875 4.4645874999999995C9.713570833333334 4.249777083333333 10.490204166666668 4.136070833333333 11.254954166666668 4.136070833333333C12.019752083333335 4.136070833333333 12.796433333333335 4.249777083333333 13.562716666666667 4.464635416666667C14.742329166666668 2.800825 16.379210416666666 1.46855 18.48495625 1.2330395833333334Z" strokeWidth="1" />
										<path id="Rectangle 1097" fill="#ffffff" d="M15.758833333333333 7.611610416666667C14.931264583333334 7.647308333333334 14.280556250000002 8.250914583333333 14.232927083333333 9.0778125C14.21069375 9.4645 14.194977083333335 9.9383 14.194977083333335 10.506160416666667S14.21069375 11.547868750000001 14.232927083333333 11.934508333333333C14.280556250000002 12.761454166666667 14.931264583333334 13.3650125 15.758833333333333 13.400758333333334C16.3415 13.425866666666668 17.120002083333336 13.446183333333334 18.11499166666667 13.446183333333334C19.10998125 13.446183333333334 19.888483333333333 13.425866666666668 20.47115 13.400758333333334C21.298718750000003 13.3650125 21.949427083333333 12.761454166666667 21.99705625 11.934508333333333C22.019337500000002 11.547868750000001 22.035006250000002 11.074020833333334 22.035006250000002 10.506160416666667S22.019337500000002 9.4645 21.99705625 9.0778125C21.949427083333333 8.250914583333333 21.298718750000003 7.647308333333334 20.47115 7.611610416666667C19.888483333333333 7.586454166666667 19.10998125 7.566185416666667 18.11499166666667 7.566185416666667C17.120002083333336 7.566185416666667 16.3415 7.586454166666667 15.758833333333333 7.611610416666667Z" strokeWidth="1.5" />
										<path id="Rectangle 1098" fill="#ffffff" d="M5.468825 7.611610416666667C4.64125625 7.647308333333334 3.9905479166666664 8.250914583333333 3.94291875 9.0778125C3.9206854166666667 9.4645 3.9050166666666666 9.9383 3.9050166666666666 10.506160416666667S3.9206854166666667 11.547868750000001 3.94291875 11.934508333333333C3.9905479166666664 12.761454166666667 4.64125625 13.3650125 5.468825 13.400758333333334C6.051491666666667 13.425866666666668 6.82999375 13.446183333333334 7.824983333333334 13.446183333333334C8.819972916666668 13.446183333333334 9.598522916666667 13.425866666666668 10.181141666666667 13.400758333333334C11.008710416666666 13.3650125 11.659466666666667 12.761454166666667 11.707095833333335 11.934508333333333C11.729329166666668 11.547868750000001 11.744997916666666 11.074020833333334 11.744997916666666 10.506160416666667S11.729329166666668 9.4645 11.707095833333335 9.0778125C11.659466666666667 8.250914583333333 11.008710416666666 7.647308333333334 10.181141666666667 7.611610416666667C9.598522916666667 7.586454166666667 8.819972916666668 7.566185416666667 7.824983333333334 7.566185416666667C6.82999375 7.566185416666667 6.051491666666667 7.586454166666667 5.468825 7.611610416666667Z" strokeWidth="1.5" />
										<path id="Ellipse 18" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M4.864452083333333 16.876154166666666C5.610610416666667 17.70640625 6.937039583333333 18.46368125 9.29501875 18.732589583333333" strokeWidth="1.5" />
										<path id="Vector 2112" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M14.194977083333335 10.506160416666667H11.744997916666666" strokeWidth="1" />
										<path id="Vector 2113" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3.9050166666666666 10.506160416666667H1.4549895833333335" strokeWidth="1" />
										<path id="glass" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M15.758833333333333 7.611610416666667C14.931264583333334 7.647308333333334 14.280556250000002 8.250914583333333 14.232927083333333 9.0778125C14.21069375 9.4645 14.194977083333335 9.9383 14.194977083333335 10.506160416666667S14.21069375 11.547868750000001 14.232927083333333 11.934508333333333C14.280556250000002 12.761454166666667 14.931264583333334 13.3650125 15.758833333333333 13.400758333333334C16.3415 13.425866666666668 17.120002083333336 13.446183333333334 18.11499166666667 13.446183333333334C19.10998125 13.446183333333334 19.888483333333333 13.425866666666668 20.47115 13.400758333333334C21.298718750000003 13.3650125 21.949427083333333 12.761454166666667 21.99705625 11.934508333333333C22.019337500000002 11.547868750000001 22.035006250000002 11.074020833333334 22.035006250000002 10.506160416666667S22.019337500000002 9.4645 21.99705625 9.0778125C21.949427083333333 8.250914583333333 21.298718750000003 7.647308333333334 20.47115 7.611610416666667C19.888483333333333 7.586454166666667 19.10998125 7.566185416666667 18.11499166666667 7.566185416666667C17.120002083333336 7.566185416666667 16.3415 7.586454166666667 15.758833333333333 7.611610416666667Z" strokeWidth="1.5" />
										<path id="Subtract" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M21.393306250000002 13.077272916666667C21.492589583333334 13.640677083333335 21.54501041666667 14.220277083333334 21.54501041666667 14.8128625C21.54501041666667 16.072927083333333 21.5449625 21.776064583333334 11.254954166666668 21.776064583333334C0.9649937500000001 21.776064583333334 0.9649937500000001 16.072927083333333 0.9649937500000001 14.8128625C0.9649937500000001 13.694583333333332 1.1517729166666668 12.6226875 1.48738125 11.618641666666667C1.465675 11.418877083333333 1.4549895833333335 11.211254166666668 1.4549895833333335 10.995964583333334C1.4549895833333335 7.9626 1.4549895833333335 3.5492833333333333 2.88836875 1.6730104166666666C3.1519104166666665 1.3280583333333333 3.5936541666666666 1.1847875 4.025047916666667 1.2330395833333334C6.1307937500000005 1.46855 7.767675 2.8007770833333336 8.9472875 4.4645874999999995C9.713570833333334 4.249777083333333 10.490204166666668 4.136070833333333 11.254954166666668 4.136070833333333C12.019752083333335 4.136070833333333 12.796433333333335 4.249777083333333 13.562716666666667 4.464635416666667C14.742329166666668 2.800825 16.379210416666666 1.46855 18.48495625 1.2330395833333334C18.91635 1.1847875 19.35814166666667 1.3280583333333333 19.621635416666667 1.6730104166666666C20.58715625 2.9368125 20.90225625 5.3517166666666665 21.00518125 7.720668750000001" strokeWidth="1.5" />
										<path id="glass" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M5.468825 7.611610416666667C4.64125625 7.647308333333334 3.9905479166666664 8.250914583333333 3.94291875 9.0778125C3.9206854166666667 9.4645 3.9050166666666666 9.9383 3.9050166666666666 10.506160416666667S3.9206854166666667 11.547868750000001 3.94291875 11.934508333333333C3.9905479166666664 12.761454166666667 4.64125625 13.3650125 5.468825 13.400758333333334C6.051491666666667 13.425866666666668 6.82999375 13.446183333333334 7.824983333333334 13.446183333333334C8.819972916666668 13.446183333333334 9.598522916666667 13.425866666666668 10.181141666666667 13.400758333333334C11.008710416666666 13.3650125 11.659466666666667 12.761454166666667 11.707095833333335 11.934508333333333C11.729329166666668 11.547868750000001 11.744997916666666 11.074020833333334 11.744997916666666 10.506160416666667S11.729329166666668 9.4645 11.707095833333335 9.0778125C11.659466666666667 8.250914583333333 11.008710416666666 7.647308333333334 10.181141666666667 7.611610416666667C9.598522916666667 7.586454166666667 8.819972916666668 7.566185416666667 7.824983333333334 7.566185416666667C6.82999375 7.566185416666667 6.051491666666667 7.586454166666667 5.468825 7.611610416666667Z" strokeWidth="1.5" />
									</g>
								</svg>
								<span className="link-text ml-4">Profile</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</section>
			<div className={"sidebar-progress-bar"}></div>
		</aside>
	)
}
