import React, { useState, useRef } from 'react';
import './NewAuthPageNavbar.scss';
import logo from '../../assets/images/branding/abun_black_text_logo.png'; // Import the Main Logo
import speaker from '../../assets/images/branding/Speaker.png'; // Import Speaker Icon
import audioFile from '../../assets/images/branding/abun_audio.mpeg'; // Import the audio file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';


//For Playing Audio
const NewAuthPageNavbar = () => {
		const [menuOpen, setMenuOpen] = useState(false);
		const audioRef = useRef<HTMLAudioElement>(null);
	  
		const handleSpeakerClick = () => {
		  if (audioRef.current) {
			audioRef.current.play();
		  }
		};

  return (
    <nav className='main-nav'>
      <div className={`nav-container ${menuOpen ? 'open' : ''}`}>
        <div className='logo'>
		<a href="https://abun.com/" style={{ cursor: 'pointer' }}><img src={logo} alt="Abun Logo" width="140px" /></a>
          <img src={speaker} alt="Speaker" width="auto" onClick={handleSpeakerClick} style={{ cursor: 'pointer' }} />
        </div>
        <div className='nav-menu'>
          <div className='nav-group'>
            <ul className={menuOpen ? "open" : ""}>
              <li><a className='nav-links' target='_blank' rel='noopener noreferrer' href="https://abun.com/#content-sample">Content Sample</a></li>
              <li><a className='nav-links' target='_blank' rel='noopener noreferrer' href="https://abun.com/#keyword-research">Keyword Research</a></li>
              <li><a className='nav-links' target='_blank' rel='noopener noreferrer' href="https://abun.com/#faq">FAQ</a></li>
              <li><a className='nav-links' target='_blank' rel='noopener noreferrer' href="https://abun.com/#price">Pricing</a></li>
              <li><NavLink className={'nav-links'} rel='noopener noreferrer' to={"/auth/login"}>Login</NavLink></li>
              <li className='last'><NavLink className={'header-cta'} rel='noopener noreferrer' to={"/auth/signup"}>GET STARTED</NavLink></li>
            </ul>
          </div>
        </div>
        <div className='res-nav' onClick={() => setMenuOpen(!menuOpen)}>
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} size="xl" /> {/* Toggle Icons */}
        </div>
        <audio ref={audioRef} src={audioFile}></audio>
      </div>
    </nav>
  );
}

export default NewAuthPageNavbar;
