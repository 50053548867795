/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Settings.scss";
import { useEffect, useRef, useState } from "react";
import { Integration, PostingDays, PostingTime } from "./Integration";
import { useLoaderData, useSearchParams, useRevalidator } from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import WebsiteDetails from "./WebsiteDetails";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import Competitors from "./Competitors";
import ArticleDetails from "./ArticleDetails";
import Backlinks from "./BacklinksPreference";
import ImageSettings, { FeatureImageTemplates, ImageSource, KeywordStrategies } from "./ImageSettings";
import AbunButton from "../../components/AbunButton/AbunButton";

type Tab = 'images' | 'integration' | 'websiteDetails' | 'competitors' | 'articleDetails' | 'backlinks';

interface ServerData {
	service: string
	keyword_strategy: KeywordStrategies
	image_source: ImageSource
	all_integrations: Array<string>
	google_search_console_integration: boolean
	google_analytics_integration: boolean
	google_drive_integration: boolean
	has_auto_publish_list: boolean
	auto_publish: 'on' | 'off' | 'paused'
	auto_publish_article_count: number
	auto_publish_days: PostingDays
	auto_publish_time: PostingTime
	next_schedule_datetime_string: string | undefined | null
	email_hours: number
	website_title: string
	website_description: string
	website_industry: string
	website_icp: string
	username: string
	email: string
	tz: string
	website_domain: string
	competitor_domains: Array<string>
	competitor_edit_underway: boolean
	feature_image_template_with_text: Array<FeatureImageTemplates>
	feature_image_template_without_text: Array<FeatureImageTemplates>
	feature_image_text: boolean
	selected_template: string
	article_tone_of_voice: string
	external_backlinks_preference: "no-follow" | "follow" | "off"
	article_language_preference: string
	max_internal_backlinks: number
	max_external_backlinks: number
	generate_bannerbear_featured_image: boolean
	ai_generated_image_style: string
	current_plan_name: string
	images_file_format: string
}

export default function Settings() {
	// ------------------------- QUERY PARAMETERS -----------------------
	const [searchParams] = useSearchParams();
	const tab = searchParams.get("tab");

	// ----------------------------- LOADER -----------------------------
	const pageData: ServerData = useLoaderData() as ServerData;
	const { revalidate } = useRevalidator();
	const { feature_image_template_with_text, feature_image_template_without_text } = pageData;

	// ---------------------------- STATES ---------------------------
	const [currentTab, setCurrentTab] = useState<Tab | string>(
		tab || 'images'
	);

	const [
		selectedTemplate,
		setSelectedTemplate
	] = useState<string>("");

	const [
		featureImageHasText,
		setfeatureImageHasText
	] = useState<boolean>();

	const [
		featuredImageTemplates,
		setFeaturedImageTemplates
	] = useState<Array<FeatureImageTemplates>>([]);

	const [
		generateBannerbearFeaturedImage,
		setGenerateBannerbearFeaturedImage
	] = useState<boolean>(false);

	const [tabChangeWarningActive, setTabChangeWarningActive] = useState(false);

	const [tabToChangeTo, setTabToChangeTo] = useState<Tab | string>("");

	const [unsavedChanges, setUnsavedChanges] = useState(false);

	// ---------------------------- REFS ----------------------------
	const errorAlertRef = useRef<any>(null);
	const successAlertRef = useRef<any>(null);

	// ---------------------------- EFFECTS ---------------------------
	useEffect(() => {
		if (pageData) {
			setfeatureImageHasText(pageData.feature_image_text);
			setSelectedTemplate(pageData.selected_template);
			setGenerateBannerbearFeaturedImage(pageData.generate_bannerbear_featured_image);
		}
	}, [pageData]);

	useEffect(() => {
		if (featureImageHasText === true) {
			setFeaturedImageTemplates(feature_image_template_with_text);
		} else {
			setFeaturedImageTemplates(feature_image_template_without_text);
		}
	}, [feature_image_template_with_text, feature_image_template_without_text, featureImageHasText]);

	useEffect(() => {
		document.title = "Settings | Abun";
	}, []);

	useEffect(() => {
		if (unsavedChanges) {
			// SAVE state to local storage
			localStorage.setItem("unsavedSettings", "true");
			// prevent user from leaving the page
		} else {
			// REMOVE state from local storage
			localStorage.removeItem("unsavedSettings");
		}
	}, [unsavedChanges]);

	// ---------------------------- FUNCTIONS ---------------------------
	function updatePageData() {
		revalidate();
	}

	// prevent user from leaving the page if they have unsaved changes
	window.onbeforeunload = function() {
		if (unsavedChanges) {
			return "You have unsaved changes. Are you sure you want to leave this page?";
		}
	}

	// =====================================================================
	// ----------------------------- MAIN JSX -----------------------------
	// =====================================================================
	const featuredImageTabContent = <ImageSettings errorAlertRef={errorAlertRef}
		successAlertRef={successAlertRef}
		featuredImageTemplates={featuredImageTemplates}
		featureImageHasText={featureImageHasText}
		selectedTemplate={selectedTemplate}
		generateBannerbearFeaturedImage={generateBannerbearFeaturedImage}
		images_file_format={pageData.images_file_format}
		keyword_strategy={pageData.keyword_strategy}
		image_source={pageData.image_source}
		aiGeneratedImageStylePreference={pageData.ai_generated_image_style}
		setSelectedTemplate={setSelectedTemplate}
		setfeatureImageHasText={setfeatureImageHasText}
		setFeaturedImageTemplates={setFeaturedImageTemplates}
		setGenerateBannerbearFeaturedImage={setGenerateBannerbearFeaturedImage}
		updatePageData={updatePageData}
		setUnsavedChanges={setUnsavedChanges}
		/>

	function renderTab() {
		switch (currentTab) {
			case "images":
				return featuredImageTabContent;
			case "integration":
				return <Integration errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					allIntegrations={pageData.all_integrations}
					googleSearchConsoleIntegration={pageData.google_search_console_integration}
					googleAnalyticsIntegration={pageData.google_analytics_integration}
					googleDriveIntegration={pageData.google_drive_integration}
					hasAutoPublishList={pageData.has_auto_publish_list}
					autoPost={pageData.auto_publish}
					postingArticleCount={pageData.auto_publish_article_count}
					postingDays={pageData.auto_publish_days}
					postingTime={pageData.auto_publish_time}
					nextScheduleDatetimeString={pageData.next_schedule_datetime_string}
					emailHours={pageData.email_hours}
					updatePageData={updatePageData}
					currentPlanName={pageData.current_plan_name}
				/>

			case "websiteDetails":
				return <WebsiteDetails errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					siteTitle={pageData.website_title}
					siteDescription={pageData.website_description}
					siteIndustry={pageData.website_industry}
					siteICP={pageData.website_icp}
					setUnsavedChanges={setUnsavedChanges} />

			case "competitors":
				return <Competitors successAlertRef={successAlertRef}
											errorAlertRef={errorAlertRef}
										   domain={pageData.website_domain}/>

			case "articleDetails":
				return <ArticleDetails errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					article_tone_of_voice={pageData.article_tone_of_voice}
					languagePreference={pageData.article_language_preference}
					updatePageData={updatePageData}
					setUnsavedChanges={setUnsavedChanges}
				/>
			case "backlinks":
				return <Backlinks errorAlertRef={errorAlertRef}
					successAlertRef={successAlertRef}
					external_backlinks_preference={pageData.external_backlinks_preference}
					max_internal_backlinks={pageData.max_internal_backlinks}
					max_external_backlinks={pageData.max_external_backlinks}
					updatePageData={updatePageData}
					setUnsavedChanges={setUnsavedChanges} />

			default:
				return featuredImageTabContent;
		}
	}

	function ChangeCurrentTab(tab: Tab) {
		// warn user if they have unsaved changes
		setTabToChangeTo(tab);
		if (unsavedChanges) {
			setTabChangeWarningActive(true);
		} else {
			setCurrentTab(tab);
		}
	}

	return (
		<>
			<div className="tabs w-100">
				<ul>
					{/* Feature Image */}
					<li className={currentTab === 'images' ? "is-active" : ""} id="images-tab"
							onClick={() => ChangeCurrentTab("images")}>
						<a>Images</a>
					</li>
					{/* Content Scheduling */}
					<li className={currentTab === 'integration' ? "is-active" : ""} id="integration-tab"
							onClick={() => ChangeCurrentTab("integration")}>
						<a>Integration</a>
					</li>
					{/* Website Details */}
					{/* <li className={currentTab === 'websiteDetails' ? "is-active" : ""}
							onClick={() => ChangeCurrentTab("websiteDetails")}>
						<a>Website Details</a>
					</li> */}
					{/* Competitors
					<li className={currentTab === 'competitors' ? "is-active" : ""}
							onClick={() => ChangeCurrentTab("competitors")}>
						<a>Competitors</a>
					</li> */}
					{/* Tone of Voice */}
					<li className={currentTab === 'articleDetails' ? "is-active" : ""}
							onClick={() => ChangeCurrentTab("articleDetails")}>
						 <a>Article Language</a>  {/* textual Changing article details to article language */}
					</li>
					{/* Backlinks */}
					<li className={currentTab === 'backlinks' ? "is-active" : ""}
							onClick={() => ChangeCurrentTab("backlinks")}>
						<a>Backlinks</a>
					</li>
				</ul>
			</div>
			<div className="tab-content w-100">
				{renderTab()}
			</div>
			<ErrorAlert ref={errorAlertRef}/>
			<SuccessAlert ref={successAlertRef}/>

			{/* Switching tabs unsaved changes warning */}
			<div className={"unsaved-settings-warning blur-background " + (tabChangeWarningActive ? "" : "hidden")}>
				<div className={"confirmation-card w-100 mt-4"}>
					<button className={"delete is-pulled-right"}
						onClick={() => {
							setTabChangeWarningActive(false);
						}}/>
					<div className={"confirmation-card-content w-100"}>
						<h3 className={"is-size-4 has-text-centered"}>
							You have unsaved changes. Are you sure you want to leave this page?
						</h3>
						<div className={"is-flex is-justify-content-center"} style={{width: "90%"}}>
							<AbunButton type={"primary"}
										className={"mt-5 is-block ml-auto mr-auto go-back-button"}
										clickHandler={() => {
											setTabChangeWarningActive(false);
										}}>
								&nbsp;&nbsp;Go Back
							</AbunButton>
							<AbunButton type={"danger"}
										className={"mt-5 is-block ml-auto mr-auto leave-button"}
										clickHandler={() => {
											setTabChangeWarningActive(false);
											setCurrentTab(tabToChangeTo as Tab);
										} }>
								&nbsp;&nbsp;Yes, Leave
							</AbunButton>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
