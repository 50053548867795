import { MutableRefObject, useState, useEffect, Dispatch, SetStateAction } from "react";
import { saveSettingsMutation } from "../../utils/api";
import { useMutation } from "@tanstack/react-query";
import Select from 'react-select';
import allSupportedLanguagesOptions from "../../utils/constants/allSupportedLanguagesOptions";

interface ArticleDetailsProps {
    errorAlertRef: MutableRefObject<any>
    successAlertRef: MutableRefObject<any>
    article_tone_of_voice: string
    languagePreference: string
    updatePageData: () => void
    setUnsavedChanges: Dispatch<SetStateAction<boolean>>
}

export type Language = {
    label: string;
    value: string;
}

export default function ArticleDetails(props: ArticleDetailsProps) {
    // --------------------- STATES ---------------------
    const [toneOfVoice, setToneOfVoice] = useState<string>(props.article_tone_of_voice);
    const [languagePreference, setLanguagePreference] = useState<string>(props.languagePreference);

    // ---------------------------- MUTATIONS ----------------------------
    const saveSettings = useMutation(saveSettingsMutation);

    // --------------------- FUNCTIONS ---------------------
    function SaveAllSettings() {
        // save all image settings
        const UpdatedLanguagePreference = allSupportedLanguagesOptions.find(option => option.value === languagePreference)?.value;
        saveSettings.mutate({
            settingsToSave: [
                { settingName: 'article_language_preference', settingValue: UpdatedLanguagePreference },
                { settingName: 'article_tone_of_voice', settingValue: toneOfVoice }
            ]
        }, {
            onSuccess: () => {
                props.updatePageData();
                props.setUnsavedChanges(false);
                props.successAlertRef.current.show("Settings updated successfully!");
                setTimeout(() => {
                    try {
                        if (props.successAlertRef.current) {
                            props.successAlertRef.current.close();
                        }
                    } catch (e) { }
                }, 3000);
            },
            onError: () => {
                props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
            }
        });
    }

	// on tab change, check if there are unsaved changes
    useEffect(() => {
        if (props.article_tone_of_voice !== toneOfVoice || props.languagePreference !== languagePreference) {
            props.setUnsavedChanges(true);
        } else {
            props.setUnsavedChanges(false);
        }
    } , [toneOfVoice, languagePreference, props]);

    // =====================================================
    // --------------------- MAIN CODE ---------------------
    // =====================================================
    return (
        <>
            {/* -=-=-=-=-=-=-=-=-=-=-= Article Tone of Voice Section -=-=-=-=-=-=-=-=-=-=-= */}
            <div className="card settings-section">
                <div className="card-content">
                    {/* Just removing the tone used in the article by commenting the code */}
                    {/* <h5 className="settings-section-title">What should be the tone used in the article?</h5>
                    <div className="control mt-5">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="off"
                                onChange={() => setToneOfVoice('off')}
                                checked={toneOfVoice === 'off'}
                            />
                            <span className="settings-radio-item-title">Off</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="exciting"
                                onChange={() => setToneOfVoice('exciting')}
                                checked={toneOfVoice === 'exciting'}
                            />
                            <span className="settings-radio-item-title">Exciting</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="humorous"
                                onChange={() => setToneOfVoice('humorous')}
                                checked={toneOfVoice === 'humorous'}
                            />
                            <span className="settings-radio-item-title">Humorous</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="funny"
                                onChange={() => setToneOfVoice('funny')}
                                checked={toneOfVoice === 'funny'}
                            />
                            <span className="settings-radio-item-title">Funny</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="formal"
                                onChange={() => setToneOfVoice('formal')}
                                checked={toneOfVoice === 'formal'}
                            />
                            <span className="settings-radio-item-title">Formal</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="tone"
                                value="serious"
                                onChange={() => setToneOfVoice('serious')}
                                checked={toneOfVoice === 'serious'}
                            />
                            <span className="settings-radio-item-title">Serious</span>
                        </label>
                    </div> */}
                    <div className="mt-5">
                        <h5 className="settings-section-title">What should be the language of the generated articles?</h5>
                        <p className="is-size-6 mt-3 mb-2 ml-2"><b>{allSupportedLanguagesOptions.find(option => option.value === props.languagePreference)?.label}</b> (Language of Articles)</p>
                        <Select
                            id="other-language-input"
                            options={allSupportedLanguagesOptions}
                            defaultValue={allSupportedLanguagesOptions.find(option => option.value === props.languagePreference)}
                            onChange={(e) => setLanguagePreference(e?.value || '')}
                        />
                    </div>
                </div>
            </div>

            {/* -=-=-=-=-=-=-=-=-=-=-= Save All Settings Button -=-=-=-=-=-=-=-=-=-=-= */}
            <div className="save-changes-section mt-4">
                <button
                    className={`button is-primary ${saveSettings.isLoading ? 'is-loading' : ''}`}
                    onClick={SaveAllSettings}
                >
                    Save Changes
                </button>
            </div>
        </>
    );
}
